<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loadIt">
      <b-col>
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col xs="6" sm="6">
              <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                <b-form-group
                  label-for="division_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.division')}}  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="form.division_id"
                  :options="divisionList"
                  id="division_id"
                  :disabled="listUserDisable()"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="6" sm="6">
              <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                <b-form-group
                  label-for="district_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.district')}}  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="form.district_id"
                  :options="districtList"
                  id="district_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :disabled="listUserDisable()"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" sm="6">
              <ValidationProvider name="Number of agents" vid="number_of_agents" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="number_of_agents">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.number_of_agents') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="number_of_agents"
                    type="number"
                    v-model="form.number_of_agents"
                    :placeholder="$t('specialTaskForce.type_english')"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="6" sm="6">
              <ValidationProvider name="Place of investigation by the Task Force" vid="task_force_activities_area" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="task_force_activities_area">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.task_force_activities_area') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="task_force_activities_area"
                    type="text"
                    v-model="form.task_force_activities_area"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" sm="12">
              <ValidationProvider name="Details of Special Task Force activities" vid="task_force_activities_description" v-slot="{ errors }">
                <b-form-group
                  label-for="task_force_activities_description">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.task_force_activities_description') }}
                  </template>
                  <b-form-textarea
                      id="remarks"
                      rows="3"
                      v-model="form.task_force_activities_description"
                      :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <div style="width: 15%;float: left;padding-right: 0px;"><p><b>{{$t('specialTaskForce.legal_action')}}</b></p></div><div style="width: 85%;float: left;padding-left: 0px;"> <hr style="border: 1px solid"></div>
            <b-col xs="6" sm="6">
              <b-form-checkbox
                  class="mt-2 mb-3"
                  id="person"
                  v-model="form.person"
                  :value="1"
                  :unchecked-value="0"
              >
                {{$t('specialTaskForce.person')}}
              </b-form-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              <ValidationProvider v-if="form.person" name="Number of persons (people)" vid="person_count" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="person_count">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.person_count') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="person_count"
                    type="number"
                    v-model="form.person_count"
                    :placeholder="$t('specialTaskForce.type_english')"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="6" sm="6">
              <b-form-checkbox
                  class="mt-2 mb-3"
                  id="institution"
                  v-model="form.institution"
                  :value="1"
                  :unchecked-value="0"
              >
                {{$t('specialTaskForce.institution')}}
              </b-form-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              <ValidationProvider v-if="form.institution" name="Number of Institutions (Count)" vid="institution_count" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="institution_count">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.institution_count') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="institution_count"
                    type="number"
                    v-model="form.institution_count"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('specialTaskForce.type_english')"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <div style="width: 13%;float: left;padding-right: 0px;"><p><b>{{$t('specialTaskForce.penalty_imposed')}}</b></p></div><div style="width: 82%;float: left;padding-left: 0px;"> <hr style="border: 1px solid"></div>
            <b-col xs="6" sm="6">
              <b-form-checkbox
                  class="mt-2 mb-3"
                  id="penalty_fine"
                  v-model="form.penalty_fine"
                  :value="1"
                  :unchecked-value="0"
              >
                {{$t('specialTaskForce.penalty_fine')}}
              </b-form-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              <ValidationProvider v-if="form.penalty_fine" name="Amount of fine (Taka)" vid="penalty_fine_taka" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="penalty_fine_taka">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.penalty_fine_taka') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="penalty_fine_taka"
                    type="number"
                    v-model="form.penalty_fine_count"
                    :placeholder="$t('specialTaskForce.type_english')"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="6" sm="6">
              <b-form-checkbox
                  class="mt-2 mb-3"
                  id="imprisonment"
                  v-model="form.imprisonment"
                  :value="1"
                  :unchecked-value="0"
              >
                {{$t('specialTaskForce.imprisonment')}}
              </b-form-checkbox>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              <ValidationProvider v-if="form.imprisonment" name="Amount of Imprisonment" vid="amount_of_imprisonment_count" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="amount_of_imprisonment_count">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.amount_of_imprisonment') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="amount_of_imprisonment_count"
                    type="text"
                    v-model="form.amount_of_imprisonment_count"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <ValidationProvider name="Attachment" vid="file" v-slot="{ errors }" rules="">
                  <b-form-group
                    label-for="attachment"
                  >
                  <template v-slot:label>
                  {{ $t('globalTrans.attachment')}}
                  </template>
                  <b-form-file
                    id="attachment"
                    v-on:change="handleFile"
                    accept="image/*"
                    v-model="form.file"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-file>
                    <p class="text-danger mt-2" style="font-size: 13px;">{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is PNG/JPG/DOC/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট PNG/JPG/DOC/JPEG/PDF]' }}</p>
                    <template v-if="isFile(form.file)">
                      <a :href="bazarMonitoringServiceBaseUrl + (isImage(form.file) ? 'storage/' : '') + form.file" target="_blank"  download><i class="ri-file-download-fill"></i></a>
                    </template>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="6" sm="12">
              <ValidationProvider name="Inspection Date" vid="inspection_date" rules="required" v-slot="{ errors }">
                <b-form-group
                  label-for="inspection_date">
                  <template v-slot:label>
                    {{ $t('priceMonitoring.inspection_date') }} <span class="text-danger">*</span>
                  </template>
                  <date-picker
                    id="inspection_date"
                    v-model="form.inspection_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select')"
                    :locale="currentLocale"
                    :config="config"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                  </date-picker>
                  <div class="invalid-feedback d-block" v-if="errors.length">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col md="12" sm="12">
              <ValidationProvider name="Remarks/Recommendation" vid="remarks" v-slot="{ errors }">
                <b-form-group
                  label-for="remarks">
                  <template v-slot:label>
                    {{ $t('specialTaskForce.remarks_recommendation') }}
                  </template>
                  <b-form-textarea
                      id="remarks"
                      rows="3"
                      v-model="form.remarks"
                      :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <b-col>
              <b-button type="submit" variant="warning" class="mr-2 btn-sm" @click="form.status = 1">{{ $t('dealer.save_draft') }}</b-button>
              <b-button type="submit" variant="success" class="mr-2 btn-sm" @click="form.status = 2">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { specialTaskForceDistrictStore, specialTaskForceDistrictUpdate } from '../../api/routes'
const today = new Date().toISOString().substr(0, 10)
export default {
  name: 'Form',
  props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      loadIt: false,
      form: {
        inspection_date: today,
        division_id: 0,
        district_id: 0,
        office_id: 0,
        number_of_agents: null,
        task_force_activities_area: '',
        task_force_activities_description: '',
        person: 1,
        person_count: null,
        institution: 1,
        institution_count: null,
        penalty_fine: 1,
        penalty_fine_count: null,
        imprisonment: 1,
        amount_of_imprisonment_count: null,
        remarks: null,
        status: 1,
        today_check: 1,
        file: ''
      },
      config: {
        maxDate: 'today',
        disable: [
          function (date) {
            const currentDate = new Date()
            const daysAgo = new Date()
            daysAgo.setDate(currentDate.getDate() - 15)
            return date < daysAgo
          }
        ]
      },
      districtList: [],
      bazarMonitoringServiceBaseUrl: bazarMonitoringServiceBaseUrl,
      isDisabled: false
    }
  },
  created () {
    if (this.id) {
      this.form = this.getData()
      this.form.today_check = 1
    }
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.org_admin === 1) {
      const officeIt = this.$store.state.CommonService.commonObj.officeList.find(item => item.status === 1 && item.value === parseInt(this.$store.state.Auth.authUser.office_detail.office_id))
      this.form.office_id = this.$store.state.Auth.authUser.office_detail.office_id
      this.form.division_id = officeIt.division_id
      this.form.district_id = officeIt.district_id
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.org_admin === 1) {
      this.form = Object.assign({}, this.form, {
        org_id: this.loggedUserPrivilege.org_id,
        // office_type_id: this.loggedUserPrivilege.office_type_id,
        office_id: this.loggedUserPrivilege.office_id,
        designation_id: this.loggedUserPrivilege.designation_id,
        user_id: this.loggedUserPrivilege.user_id
      })
    }
    const loadingState = { loading: false, listReload: false }
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (this.$route.params.id) {
      this.saveUpdate()
    }
  },
  watch: {
    'form.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal !== 0) {
          this.districtList = this.getDistrictList(newVal)
        }
      } else {
        this.districtList = []
      }
    }
  },
  methods: {
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    isFile (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg|docs|doc|pdf|csv|xsl|xslx|ppt|pptx)$/.test(path)
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    handleFile (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.form.file = event.target.result
      }
      reader.readAsDataURL(selectedPhoto)
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === parseInt(id))
    },
    listUserDisable () {
      if (this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.org_admin === 2) {
        return false
      }
      return true
    },
    async saveUpdate () {
      this.loadIt = true
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(bazarMonitoringServiceBaseUrl, `${specialTaskForceDistrictUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(bazarMonitoringServiceBaseUrl, specialTaskForceDistrictStore, this.form)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('BazarMonitoringService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-form')
      } else {
        this.loadIt = false
        if (parseInt(result.type) === 2) {
          window.vm.$swal({
            title: window.vm.$t('specialTaskForce.are_you_sure'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.form.today_check = 0
              this.saveUpdate()
              // this.changeStatus(3, bazarMonitoringServiceBaseUrl, priceEntryTogle, item)
            }
          })
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    }
  }
}
</script>
