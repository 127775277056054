<template>
  <b-row>
   <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-overlay :show="loading">
                      <b-row>
                        <b-col>
                          <list-report-head :base-url="bazarMonitoringServiceBaseUrl" uri="/configuration/report-head/detail" :org-id="1">
                            <template v-slot:projectNameSlot>
                              {{ }}
                            </template>
                            <u>{{ $t('specialTaskForce.district_special_task_force_activities') + ' ' + $t('globalTrans.details') }}</u>
                          </list-report-head>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <div>
                            <b-table-simple  class="table table-borderless table-now-it">
                              <b-tr>
                                <b-td style="width:27%">{{ $t('globalTrans.division') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td colspan="4" class="text-left" style="width:70%">{{ getColumnName($store.state.CommonService.commonObj.divisionList, detailsData.division_id)}}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%">{{ $t('globalTrans.district') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td colspan="4" class="text-left" style="width:70%">{{ getColumnName($store.state.CommonService.commonObj.districtList, detailsData.district_id)}}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%">{{ $t('specialTaskForce.number_of_agents') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td colspan="4" class="text-left" style="width:70%">{{ $n(detailsData.number_of_agents, { useGrouping: false }) }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%">{{ $t('specialTaskForce.task_force_activities_area') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" colspan="4" style="width:70%">{{ detailsData.task_force_activities_area }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%">{{ $t('specialTaskForce.task_force_activities_description') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" colspan="4" style="width:70%">{{ detailsData.task_force_activities_description }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th class="text-left" colspan="6">{{$t('specialTaskForce.legal_action')}}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%">{{ $t('specialTaskForce.person_count') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" style="width:22%"><slot v-if="detailsData.person">{{ $n(detailsData.person_count, { useGrouping: false }) }}</slot></b-td>
                                <b-td style="width:23%">{{ $t('specialTaskForce.institution_count') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" style="width:22%"><slot v-if="detailsData.institution">{{ $n(detailsData.institution_count, { useGrouping: false }) }}</slot></b-td>
                              </b-tr>
                              <b-tr>
                                <b-th class="text-left" colspan="6">{{$t('specialTaskForce.penalty_imposed')}}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%;font-size: 13px">{{ $t('specialTaskForce.penalty_fine_taka') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" style="width:18%" ><slot v-if="detailsData.penalty_fine">{{ $n(detailsData.penalty_fine_count, { useGrouping: false }) }}</slot></b-td>
                                <b-td style="width:23%">{{ $t('specialTaskForce.amount_of_imprisonment') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" style="width:22%"><slot v-if="detailsData.imprisonment">{{ detailsData.amount_of_imprisonment_count }}</slot></b-td>
                              </b-tr>
                              <b-tr>
                                <b-td style="width:27%">{{ $t('globalTrans.attachment') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" style="width:18%">
                                  <template v-if="isFile(detailsData.file)">
                                    <a style="padding: 0px" :href="bazarMonitoringServiceBaseUrl + (isImage(detailsData.file) ? 'storage/' : '') + detailsData.file" target="_blank"  download><i class="ri-file-download-fill"></i></a>
                                  </template>
                                </b-td>
                                <b-td style="width:27%">{{ $t('priceMonitoring.inspection_date') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" style="width:18%">{{ detailsData.inspection_date | dateFormat }}</b-td>
                              </b-tr>
                               <b-tr>
                                <b-td style="width:27%">{{ $t('specialTaskForce.remarks_recommendation') }}</b-td>
                                <b-td class="text-center" style="width:3%">:</b-td>
                                <b-td class="text-left" colspan="4" style="width:70%">{{ detailsData.remarks }}</b-td>
                              </b-tr>
                            </b-table-simple>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-row>
</template>
<script>
import { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import bazarMonitoringServiceMixin from '@/mixins/bazar-monitoring-service'
const today = new Date().toISOString().substr(0, 10)

export default {
  name: 'Details',
  mixins: [bazarMonitoringServiceMixin],
  props: ['id', 'data', 'report'],
  components: {
    ListReportHead
  },
  data () {
    return {
      valid: null,
      loading: false,
      today: today,
      errors: [],
      detailsData: [],
      officer: {},
      bazarMonitoringServiceBaseUrl: bazarMonitoringServiceBaseUrl
    }
  },
  created () {
    if (this.report) {
      this.detailsData = this.data
    } else {
      if (this.id) {
        const tmp = this.getMarketInspectionReportDetails()
        this.detailsData = tmp
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
        return ''
      }
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    isFile (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg|docs|doc|pdf|csv|xsl|xslx|ppt|pptx)$/.test(path)
    },
    getMarketInspectionReportDetails () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
<style scoped>
  .table-now-it th, .table-now-it td {
    padding: 0px !important;
  }
</style>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
